import React, { useContext } from "react";
import { StateContext } from "../state/StateProvider";

const Message = () => {
  const { message, setMessage } = useContext(StateContext);
  return (
    <div className="wrapper">
      <h1>Current message</h1>
      <p>{message}</p>
      <label htmlFor="set-message">Set new message</label>
      <input type="text" id="set-message" placeholder="Message..." />
      <button
        onClick={() => setMessage(document.getElementById("set-message").value)}
      >
        Set
      </button>
    </div>
  );
};

export default Message;
